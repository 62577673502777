/* --------------------------------
    SITE SIPAUI
    CSS Accueil
-------------------------------- */
$hauteurCouv: 450px;
$hauteurCouvTablette: 350px;
$hauteurCouvDesktopLarge: 500px;

.accueil {
    .couv {
        position: absolute;
        width: 100vw;
        height: $hauteurCouv;
        left: 0;
        overflow: hidden;
        margin-top: -$hauteurCouv;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: url(/doc/images/accueil-illustration-anim.svg) no-repeat, url(/doc/images/accueil-illustration.svg), linear-gradient(20deg, #f16878 0%, $rouge-2 15%, $rouge-2 55%, #F47080 100%);
        
        .texte {
            h1,
            p {
                color: var(--blanc);
            }
        }
        h1 {
            font-size: var(--font-size-xxl);
            
            span {
                font-size: 6rem;
            }
        } 
        // .logo { // Texte en SVG animé
        //     color: transparent;
        //     background: url(/doc/images/accueil-titre.svg) no-repeat 2px 4px;
        //     background-size: 97%; 
        // }
    }
    main.contenu {
        padding-top: $hauteurCouv;
        
        .su-article {
            padding-top: 0 !important;
        }
    }
    .cartes {
        padding: var(--marge-xxxl) 0;
    }
    .carte {
        .su-main-picture {
            background: rgba($rouge-2, .2);
            border-radius: $border-radius-standard;
        }
        .su-title {
            margin: var(--marge-s) 0 var(--marge-s);
        }
        &:last-child {
            margin: auto; // Pour centrer en mobile
        }
    }
}

// -------------------------------------------------- TABLETTE ------------------------------------------------------------------
@media screen and (min-width: 48em) { // >768px
    .accueil {
        .couv {
            height: $hauteurCouvTablette;
            margin-top: -$hauteurCouvTablette;
        }
        main.contenu {
            padding-top: $hauteurCouvTablette;
        }
    }
}

// -------------------------------------------------- DESKTOP LARGE------------------------------------------------------------------
@media screen and (min-width: 80em) {
    .accueil {
        .couv {
            height: $hauteurCouvDesktopLarge;
            margin-top: -$hauteurCouvDesktopLarge;
            .texte {
               p {
                   font-size: var(--font-size-l);
               }
            }
        }
        main.contenu {
            padding-top: $hauteurCouvDesktopLarge;
        }
    }
}




/* --------------------------------
    SITE SIPAUI
    CSS Outils
-------------------------------- */
$shadow-objets-volants: rgba($color-main-7, .1) 1px 1px 10px 2px;

// Bouton remonter
#remonter {
    position: fixed;
    bottom: var(--marge-m);
    right: 0;
    transform: translateX(calc(var(--marge-m) * -1));
    background: $color-main-1;
    display: none;
    box-shadow: $shadow-button-secondary, $shadow-objets-volants;
    z-index: 500;
    
    &.inactif {
        opacity: .2;
        pointer-events: none;
    }
    .su-icon {
        display: block;
        transform: translateY(-1px);
    }
}
.afficher-remonter #remonter {
    display: flex;
}

#outils {
    position: sticky;
    top: 112px;
    top: $height-header;
    padding: 46px 0 50px;
    right: 0;
    z-index: 1;
    display: flex;
    
    .su-container {
        display: flex;
        gap: var(--marge-m);
    }
}

header:not(.sans-ombre) + .contenu #outils {
    background-image: linear-gradient(rgba(255,255,255,.9) 60%, rgba(255,255,255,0) 100%);
    backdrop-filter: blur(.2px)
}

.doc-js #outils {
    display: none;
}

// ------------------------------------------------------------------ DESKTOP ------------------------------------------------------------------
@media screen and (min-width: 80em) { // >1280px
    #remonter {
        transform: translateX(calc((100vw - $largeur-nav-max) / -2 + $largeur-max-conteneur-desktop-large / 2 + var(--taille-tactile) + var(--marge-s)));
    }
}

// --------------------------------------------------- Desktop > 1600px ------------------------------------------------------------------
@media screen and (100em <= width) { // >= 1600px
    #remonter {
        transform: translateX(calc((100vw - $largeur-nav-max) / -2 + $largeur-max-conteneur-desktop-extra-large / 2 + var(--taille-tactile) + var(--marge-s)));
    }
}

/* --------------------------------
    SITE SIPAUI
    CSS Logothèque
-------------------------------- */

.page-logotheque {
    .carte-logo {
        display: flex;
        flex-direction: column;
        
        h3 {
            flex: 1;
        }
        .boutons {
            display: flex;
            justify-content: center;
            gap: var(--marge-m);
        }
    }
}

// -------------------------------------------------- DESKTOP ------------------------------------------------------------------
@media screen and (min-width: 61.25em) and (max-width: 79.938em) {
    .page-logotheque {
        .carte-logo {
            .boutons {
                .su-button {
                    min-width: 70px;
                }
            }
        }
    }
}

/* --------------------------------
    SITE SIPAUI
    CSS Framework
-------------------------------- */


.boutons-demo {
    @include boutons-demo;
    border-radius: 5px;
   
   &.petit {
        padding: var(--marge-xs) var(--marge-s);
    }
}

.su-button.petit {
    transform: scale(80%) translateY(-10px);
    height: 39px !important;
    min-width: 39px !important;
}

/* --------------------------------
    SITE SIPAUI
    CSS de redéfinition des composants SipaUI
-------------------------------- */


// TITRES ------------------------------------------------------

.sipaui {
    .su-article {
        :is(h2,
        .su-h2) {
            margin-top: var(--marge-xxxl);
        }
        :is(h3,
        .su-h3) {
            font-size: var(--font-size-xl);
            margin-top: var(--marge-xl);
        }
        h4 {
            margin-top: var(--marge-xl);
            margin-bottom: var(--marge-s);
            font-family: Oswald, Arial, Helvetica, sans-serif;
            font-size: var(--font-size-ml);
            font-weight: var(--font-weight-normal);
        }
        h5 {
            margin-top: var(--marge-m);
            margin-bottom: var(--marge-s);
            font-family: Oswald, Arial, Helvetica, sans-serif;
            font-size: var(--font-size-m);
            font-weight: var(--font-weight-normal);
            //color: red !important;
        }
        label h2,
        label h3,
        label h4 {
            margin: var(--marge-s) 0;
        }
    }
    table {
        width: 100% !important;
    }
}

// ------------------------------------------------------------------ DESKTOP ---------------------------------------------------------------
@media screen and (min-width: 61.25em) {  // >980px
    .sipaui .su-container, .sipaui.su-container {
        max-width: $largeur-max-conteneur-desktop;
    }
}

// ------------------------------------------------------------------ DESKTOP 1090---------------------------------------------------------------
@media screen and (min-width: 68.125em) {  // >1090px
    .sipaui .su-container, .sipaui.su-container {
        max-width: $largeur-max-conteneur-desktop-large;
    }
}

// ------------------------------------------------------------------ DESKTOP LARGE---------------------------------------------------------------
// @media screen and (min-width: 80em) {  // >1280px
//     .sipaui .su-container, .sipaui.su-container {
//         max-width: $largeur-max-conteneur-desktop-large;
//     }
// }
// ------------------------------------------------------------------ DESKTOP EXTRA LARGE---------------------------------------------------------------
@media screen and (min-width: 100em) {  // >1600px
    .sipaui .su-container, .sipaui.su-container {
        max-width: $largeur-max-conteneur-desktop-extra-large;
    }
}

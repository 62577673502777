// PALETTE SIPA ———————————————————————————————————————————————————————————

// Gris (suite de base)
$blanc:                     #fff;       // correspondance sombre : #333     $anthracite
$quasi-blanc:               #fafafa;    // correspondance sombre : #333     $anthracite
$gris-plus-clair:           #efefef;    // correspondance sombre : #333     $anthracite
$gris-clair:                #e4e3e1;    // correspondance sombre : #333     $anthracite
$gris:                      #d4d4d4;    // correspondance sombre : #858585  $gris-fonce
$gris-fonce:                #858585;    // correspondance sombre : #d4d4d4  $gris
$gris-plus-fonce:           #666;       // correspondance sombre : #efefef  $gris-plus-clair
$anthracite:                #333;       // correspondance sombre : #fff     $blanc
$quasi-noir:                #121212;    // correspondance sombre : #121212  pas de changement
$noir:                      #000;       // correspondance sombre : #fff     $blanc

// Couleurs
// Règles fond sombre :
    // couleur-1 -> couleur-2
    // couleur-3 -> couleur-4
$rouge-1:                   #e2001a; // Rouges OF - CO + erreur
$rouge-2:                   #eb4d5f;
$rouge-3:                   #fce5e8;
$rouge-4:                   #271013;

$vert-1:                    #3aaa35; // Vert validation
$vert-2:                    #75c472;
$vert-3:                    #ebf6ea;
$vert-4:                    #162115;

$jaune-1:                   #cba200; // Jaunes Abonnement
$jaune-2:                   #dbbe4d;
$jaune-3:                   #faf6e6;
$jaune-4:                   #242010;

// ----------------------------------------------------------------
// Marques
$noir-br-1:                 #1b181c; // Noirs Bretons
$noir-br-2:                 #5f5d60;

$bleu-jdlo-1:               #009adc; // Bleus Maine-Libre Presse-Océan
$bleu-jdlo-2:               #4db8e6;

$bleu-vv-1:                 #20407a; // Bleus Voiles & Voiliers
$bleu-vv-2:                 #6379a2;
    $bleu-vv:                 $bleu-vv-1; // CSS pour rétrocompatibilité à supprimer dans SipaUI 8

$bleu-bc-1:                 #224672; // Bleus Bretons en Cuisine
$bleu-bc-2:                 #647e9c;

$bleu-vo-1:                 #005b90; // Bleus Voyages à l'Ouest
$bleu-vo-2:                 #4d8cb1;

$bleu-pdld-1:               #082e65; // Bleu Prix de la démocratie
    $bleu-pdld:                 $bleu-pdld-1; // CSS pour rétrocompatibilité à supprimer dans SipaUI 8

$bleu-clair-ic:             #e3eaf8;  // Bleus et gris-bleus Infoconnect
$gris-bleu-ic-1:            #29354e;
$gris-bleu-ic-2:            #6a7283;
    $gris-bleu-ic:              $gris-bleu-ic-1; // CSS pour rétrocompatibilité à supprimer dans SipaUI 8

$rouge-im-1:                #e30613; // Rouges Infomer Le Marin
$rouge-im-2:                #eb515a;

$rouge-lm-1:                #e30613; // Rouges Lire Magazine
$rouge-lm-2:                #eb515a;

$rouge-tj-1:                #ea4f52; // Rouges Terre de jardin
$rouge-tj-2:                #f08486;

/* --------------------------------
    SITE SIPAUI
    CSS Versions
-------------------------------- */

.page-versions {
    .breaking .motif {
        color: var(--rouge);
    }
    .version-bloc {
        margin-bottom: var(--marge-xxl);
    }
    .version-numero {
        margin-bottom: var(--marge-m);
    }
    .su-accordion-item  h2 {
        margin-top: var(--marge-s) !important;
        margin-bottom: var(--marge-s) !important;
    }
}

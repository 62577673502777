/* --------------------------------
    SITE SIPAUI
    CSS Couleurs
-------------------------------- */

@font-face {
    font-family: 'Oswald';
    src: url('/fonts/oswald/Oswald-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display:swap
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/raleway/Raleway-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display:swap
}
@font-face {
    font-family: 'Raleway';
    src: url('/fonts/raleway/Raleway-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display:swap
}


.page-typographie {
    .vignette {
        height: auto;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 7rem;
    }
    .fonte {
        font-size: var(--font-size-l);
        overflow-wrap: break-word
    }
    .arial,
    .arial-italic,
    .arial-bold {
        font-family: Arial;
    }
    .arial-italic {
        font-style: italic;
    }
    .arial-bold {
        font-weight: var(--font-weight-bold);
    }

    .oswald {
        font-family: Oswald;
    }

    .raleway,
    .raleway-bold {
        font-family: Raleway;
    }
    .raleway-bold {
        font-weight: var(--font-weight-bold);
    }
}

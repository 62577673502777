/* --------------------------------
    SITE SIPAUI
    CSS Variables
-------------------------------- */


.page-variables {
    #couleurs {
        ~ table {
            td:nth-child(2),
            td:nth-child(3) {
                width: 25%;
            }
            td:nth-child(4) {
                width: 15%;
            }
            td:last-child {
                width: 40px;
                text-align: right;
            }
        }
    }
    #typographie {
        ~ table {
            td {
                width: 25%;
            }
        }
    }
}

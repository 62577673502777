/* --------------------------------
    SITE SIPAUI
    CSS header
-------------------------------- */

header.barre-haute {
    background: var(--blanc);
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0,0,0, .2);
    transition: box-shadow .5s ease;
    padding: 0 var(--marge-m);
    
    &.sans-ombre {
        box-shadow: unset;
    }
    .contenu {
        height: $height-header;
        align-items: center;
        position: relative;
    }
    #identite,
    #navigation {
        display: flex;
        align-items: center;
        height: $height-contenu-header;
        gap: var(--marge-m);
    }
    .logo {
        height: $height-contenu-header;
    }
    .version-neutre {
        font-size: var(--font-size-xs);
        padding: 0;
        transform: translateY(6px);
    }
    // NAVIGATION ---------------
    nav {
        #navigation {
            gap: 0;
        }
        
                                                                                                        .ouvrir-menu,
                                                                                                        .fermer-menu { // a supprimer quand le bouton Menu/fermer sera fait dans SipaUI
                                                                                                            width: var(--taille-tactile);
                                                                                                            height: var(--taille-tactile);
                                                                                                        }
    }
}

// ------------------------------------------------------------------ MOBILE ------------------------------------------------------------------
@media screen and (max-width: 47.938em) { // <767PX
    header.barre-haute {
        #navigation {
            display: block;
            height: calc(100vh - #{$height-header});
            width: 100vw;
            //overflow: hidden;
            position: absolute;
            top: $height-header;
            right: calc(-100vw - var(--marge-m));
            background: var(--blanc);
            opacity: 0; // pour éviter que le menu apparaisse temporairement pdt le redimensionnement;
            transition: right ease-in .2s, opacity 3s;
            

            &.ouvert {
                opacity: 1;
                right: calc(var(--marge-m) * -1);
                transition: right ease-out .4s, opacity .1s;
            }
            li {
                line-height: var(--taille-tactile);
                border-bottom: 1px solid var(--gris);
                
                a {
                    display: block;
                    height: var(--taille-tactile);
                    width: 100%;
                    padding-left: var(--marge-m);
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                }
            }
            li:not(:last-child) a:after {
                content: "\e90a";
                font-family: su-icons;
                font-size: 2.4rem;
                margin-right: var(--marge-s)
            }
        }
    }
}

// ------------------------------------------------------------------ TABLETTE & + ------------------------------------------------------------------
@media screen and (min-width: 48em) { // >768px
    header.barre-haute {
        nav {
            button {
                display: none;
            }
            li {
                font-weight: var(--font-weight-bold);
                
                a {
                    position: relative;
                    display: block;
                    height: $height-header;
                    padding: var(--marge-m);
                    
                    &::after {
                        content: "";
                        width: 100%;
                        height: 4px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: var(--gris);
                        transition: .5s transform ease;
                        transform: scale(0,1);
                        transform-origin: 0 50%;
                    }
                }
                a:hover::after,
                &.ouvert a::after {
                    transform: scale(1,1);
                }
                &.ouvert a {
                    color: var(--rouge);
                    &::after {
                        background: var(--rouge);
                    }
                }
                &.ouvert a:hover {
                    cursor: default;
                    color: var(--rouge);
                }
            }
        }
    }
}

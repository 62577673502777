/*
    variables liées au composant [buttons]
    ex:
    $border-button-premium: 3px !default;
*/



// BOUTONS
$font-family-button:    null                                !default;
$font-size-button:      $font-size-standard                 !default;
$side-padding-button:   20px                                !default;
$height-button:         var(--taille-tactile)               !default;

$font-size-button-small:      var(--font-size-s)            !default;
$side-padding-button-small:   15px                          !default;
$height-button-small:         var(--taille-tactile-mini)    !default;


// PRIMARY -------------------------------------------------------------------------------------------/
$background-button-primary:     $color-secondary-1          !default;
$color-button-primary:          $color-main-1               !default;
$text-transform-button-primary: none                        !default;
$border-button-primary:         0                           !default;
$border-radius-button-primary:  $border-radius-standard     !default;
$shadow-button-primary:         null                        !default;
$font-weight-button-primary:    var(--font-weight-bold)     !default;

// hover, focus
$background-button-primary-hover:   null                    !default;
$color-button-primary-hover:        $color-button-primary   !default;
$border-button-primary-hover:       null                    !default;
$shadow-button-primary-hover:       null                    !default;
$opacity-button-primary-hover:      $opacity-hover          !default;
// active
$background-button-primary-active:  $background-button-primary-hover    !default;
$color-button-primary-active:       $color-button-primary-hover         !default;
$border-button-primary-active:      $border-button-primary-hover        !default;
$shadow-button-primary-active:      $shadow-button-primary-hover        !default;
$opacity-button-primary-active:     $opacity-button-primary-hover       !default;

// negatif
$background-button-primary-negative:  $color-secondary-1-negative       !default;
$color-button-primary-negative:       null                              !default;
$border-button-primary-negative:      null                              !default;
$shadow-button-primary-negative:      null                              !default;
// negatif hover, focus
$background-button-primary-negative-hover:  null                           !default;
$color-button-primary-negative-hover:       $color-button-primary-negative !default;
$border-button-primary-negative-hover:      null                           !default;
$shadow-button-primary-negative-hover:      null                           !default;
$opacity-button-primary-negative-hover:     null                           !default;
// negatif active
$background-button-primary-negative-active:  $background-button-primary-negative-hover        !default;
$color-button-primary-negative-active:       $color-button-primary-negative-hover             !default;
$border-button-primary-negative-active:      $border-button-primary-negative-hover            !default;
$shadow-button-primary-negative-active:      $shadow-button-primary-negative-hover            !default;
$opacity-button-primary-negative-active:     null                                             !default;

$filter-img-button-primary:                   invert(100%) brightness(140%)                    !default;
$filter-img-button-primary-negative:          null                                             !default;

// SECONDARY -------------------------------------------------------------------------------------------/
$background-button-secondary:     transparent                                       !default;
$color-button-secondary:          $font-color-standard                              !default;
$text-transform-button-secondary: none                                              !default;
$border-button-secondary:         none                                              !default;
$border-radius-button-secondary:         $border-radius-button-primary              !default;
$shadow-button-secondary:         0 0 0 1px $color-button-secondary inset           !default;
$font-weight-button-secondary:    var(--font-weight-normal)                         !default;

// hover, focus
$background-button-secondary-hover:   $background-button-secondary                  !default;
$color-button-secondary-hover:        $color-button-secondary                       !default;
$border-button-secondary-hover:       none                                          !default;
$shadow-button-secondary-hover:       null                                          !default;
$opacity-button-secondary-hover:      $opacity-hover                                !default;
// active
$background-button-secondary-active:  $background-button-secondary-hover            !default;
$color-button-secondary-active:       $color-button-secondary-hover                 !default;
$border-button-secondary-active:      $border-button-secondary-hover                !default;
$shadow-button-secondary-active:      $shadow-button-secondary-hover                !default;
$opacity-button-secondary-active:     $opacity-button-secondary-hover               !default;

// negatif
$background-button-secondary-negative:  null                                                           !default;
$color-button-secondary-negative:       $color-link-negative                                           !default;
$border-button-secondary-negative:      null                                                           !default;
$shadow-button-secondary-negative:      0 0 0 1px $color-button-secondary-negative inset               !default;
// negatif hover, focus
$background-button-secondary-negative-hover:  $background-button-secondary-negative                    !default;
$color-button-secondary-negative-hover:       $color-button-secondary-negative                         !default;
$border-button-secondary-negative-hover:      null                                                     !default;
$shadow-button-secondary-negative-hover:      null                                                     !default;
$opacity-button-secondary-negative-hover:     null                                                     !default;
// negatif active
$background-button-secondary-negative-active:  $background-button-secondary-negative-hover             !default;
$color-button-secondary-negative-active:       $color-button-secondary-negative-hover                  !default;
$border-button-secondary-negative-active:      $border-button-secondary-negative-hover                 !default;
$shadow-button-secondary-negative-active:      $shadow-button-secondary-negative-hover                 !default;
$opacity-button-secondary-negative-active:     $opacity-button-secondary-negative-hover                !default;

$filter-img-button-secondary:                   invert(0%) brightness(0%)                               !default;
$filter-img-button-secondary-negative:          invert(100%) brightness(140%)                           !default;

// NEUTRAL -------------------------------------------------------------------------------------------/
$background-button-neutral:     var(--couleur-neutre-1)                            !default;
$color-button-neutral:          var(--couleur-neutre-2)                            !default;
$text-transform-button-neutral: none                                               !default;
$border-button-neutral:         none                                               !default;
$border-radius-button-neutral:  $border-radius-button-primary                      !default;
$shadow-button-neutral:         null                                               !default;
$font-weight-button-neutral:    var(--font-weight-bold)                            !default;

// hover, focus
$background-button-neutral-hover:   $background-button-neutral                     !default;
$color-button-neutral-hover:        $color-button-neutral                          !default;
$border-button-neutral-hover:       none                                           !default;
$shadow-button-neutral-hover:       null                                           !default;
$opacity-button-neutral-hover:      $opacity-hover                                 !default;
// active
$background-button-neutral-active:  $background-button-neutral-hover               !default;
$color-button-neutral-active:       $color-button-neutral-hover                    !default;
$border-button-neutral-active:      $border-button-neutral-hover                   !default;
$shadow-button-neutral-active:      $shadow-button-neutral-hover                   !default;
$opacity-button-neutral-active:     $opacity-button-neutral-hover                  !default;

// negatif
$background-button-neutral-negative:  var(--couleur-neutre-2)                                        !default;
$color-button-neutral-negative:       var(--couleur-neutre-1)                                        !default;
$border-button-neutral-negative:      null                                                           !default;
$shadow-button-neutral-negative:      null                                                           !default;
// negatif hover, focus
$background-button-neutral-negative-hover:  $background-button-neutral-negative                      !default;
$color-button-neutral-negative-hover:       $color-button-neutral-negative                           !default;
$border-button-neutral-negative-hover:      null                                                     !default;
$shadow-button-neutral-negative-hover:      null                                                     !default;
$opacity-button-neutral-negative-hover:     null                                                     !default;
// negatif active
$background-button-neutral-negative-active:  $background-button-neutral-negative-hover               !default;
$color-button-neutral-negative-active:       $color-button-neutral-negative-hover                    !default;
$border-button-neutral-negative-active:      $border-button-neutral-negative-hover                   !default;
$shadow-button-neutral-negative-active:      $shadow-button-neutral-negative-hover                   !default;
$opacity-button-neutral-negative-active:     $opacity-button-neutral-negative-hover                  !default;

// SUBSCRIPTION -------------------------------------------------------------------------------------------/
$background-button-subscription:     $color-subscription-1             !default;
$color-button-subscription:          $color-main-1                     !default;
$text-transform-button-subscription: none                              !default;
$border-button-subscription:         0                                 !default;
$border-radius-button-subscription:  $border-radius-button-primary     !default;
$shadow-button-subscription:         null                              !default;
$font-weight-button-subscription:    var(--font-weight-bold)           !default;

// hover, focus
$background-button-subscription-hover:   null                          !default;
$color-button-subscription-hover:        $color-button-subscription    !default;
$border-button-subscription-hover:       null                          !default;
$shadow-button-subscription-hover:       null                          !default;
$opacity-button-subscription-hover:      $opacity-hover                !default;
// active
$background-button-subscription-active:  $background-button-subscription-hover  !default;
$color-button-subscription-active:       $color-button-subscription-hover       !default;
$border-button-subscription-active:      $border-button-subscription-hover      !default;
$shadow-button-subscription-active:      $shadow-button-subscription-hover      !default;
$opacity-button-subscription-active:     $opacity-button-subscription-hover     !default;

$filter-img-button-subscription:          invert(100%) brightness(140%)          !default;


$height-icon:                            24px                                   !default;

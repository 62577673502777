/* --------------------------------
    SITE SIPAUI
    CSS Navigation secondaire
-------------------------------- */

 $marge-haute-nav: var(--marge-xxl);
 $marge-basse-filtre: var(--marge-xl);
 $couleur-panneau-ouvert: null;

 main.contenu {
    aside {
       flex: 0 0 $largeur-nav-min;
       background: $rouge-2;
    }
    nav.menu-secondaire {
        padding: $marge-haute-nav 0 0;
        position: sticky;
        top: $height-header;

        .fichier {
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            -webkit-line-clamp: 1;
        }

        > ul {
            overflow: auto;
            max-height: calc(100vh - $height-header - $marge-haute-nav - var(--taille-tactile) - $marge-basse-filtre);

            > li:last-child {
                margin-bottom: var(--marge-xl);
            }
        }
        > ul > .avec-sous-menus {
            border: 1px solid rgba($gris-plus-clair, .2);
            border-left: 0;
            border-right: 0;

            & ~ .avec-sous-menus {
                border-top: 0;
            }
        }
        button, a {
            display: block;
            padding-right: var(--marge-m);
            height: var(--taille-tactile);
            line-height: var(--taille-tactile);
            color: $blanc !important;

            &:hover {
                cursor: pointer;
                color: rgba($blanc, $opacity-hover) !important;
            }
        }
        button {
            font-weight: var(--font-weight-bold);
            width: 100%;
            text-align: left;
            padding: 0 var(--marge-m);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .su-icon {
                display: inline-block;
            }
            & + ul > li > button {
                padding-left: calc( var(--marge-m) * 2);

                & + ul > li > button {
                    padding-left: calc( var(--marge-m) * 3);
                }
            }
        }
        .ouvert {
            & > a {
                background: $rouge-1 !important;
                font-weight: var(--font-weight-bold);
                text-decoration: underline;
                text-decoration-color: var(--couleur-of-1);

                &:hover {
                    cursor: default;
                    color: var(--anthracite);
                }
            }
            & > button {
                background: $couleur-panneau-ouvert;

                + ul {
                    background: $couleur-panneau-ouvert;
                }
                .su-icon {
                    transform: rotate(180deg);
                }
            }
        }
        ul {
            a {
                padding-left: calc( var(--marge-m) * 1);
            }
            ul {
                a {
                    padding-left: calc( var(--marge-m) * 2);
                }
                ul {
                    a {
                        padding-left: calc( var(--marge-m) * 3);
                    }
                    ul {
                        a {
                            padding-left: calc( var(--marge-m) * 4);
                        }
                    }
                }
            }
        }
        .champ-filtre {
            padding: 0 var(--marge-s) $marge-basse-filtre;
        }
        &:not(.recherche-active) .avec-sous-menus:not(.ouvert) > *:not(button) { //masque tous les sous-menu par défaut
            display: none;
        }
        &.recherche-active .avec-sous-menus *,
        &.recherche-active > ul > li > a {
            display: none;
        }
        &.recherche-active > .resultat-recherche .resultat-recherche {
            display: block !important;
            background: $couleur-panneau-ouvert;
        }
        &.recherche-active > .resultat-recherche .resultat-recherche > button {
            display: flex !important;
        }
     }
 }

// ------------------------------------------------------------------ MOBILE ------------------------------------------------------------------
@media screen and (max-width: 47.938em) { // <767px
    main.contenu aside {
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // z-index: 10;
        // width: 100%;
        // width: 100vw;
        // transform: translatex(95vw);
        // z-index: 1000;
        display: none;
    }
}


// ------------------------------------------------------------------ PETIT DESKTOP ------------------------------------------------------------------
@media screen and (min-width: 48em) and (max-width: 68.063em) { // >768px   <1090px
    main.contenu aside {
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // z-index: 10;
        // width: 100%;
        // max-width: $largeur-nav-min;
        //transform: translatex($largeur-nav-max * -1)
        display: none;
    }
}


// ------------------------------------------------------------------ DESKTOP LARGE------------------------------------------------------------------
@media screen and (min-width: 80em) { // >1280px
    main.contenu {
        aside {
            flex: 0 0 $largeur-nav-max;
        }
    }
}

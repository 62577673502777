/* --------------------------------
    SITE SIPAUI
    CSS Couleurs
-------------------------------- */

.page-couleurs {
    .palette-couleurs,
    .mini-palette-couleurs {
        .couleur {
            overflow: hidden;
        }
        p {
            margin: var(--marge-xs) 0  0 !important;
        }
    }
    .palette-couleurs {
        .echantillon {
            height: 60px;
            margin: calc(var(--marge-m) * -1) calc(var(--marge-m) * -1) var(--marge-m);
        }
    }
    .mini-palette-couleurs {
        .echantillon {
            height: 60px;
            margin: calc(var(--marge-m) * -1) calc(var(--marge-m) * -1) var(--marge-m);
        }
    }
    p + .palette-couleurs {
        margin-top: var(--marge-m);
    }
    .fond-sombre {
        background: var(--quasi-noir) !important;
        color: var(--gris) !important;
    }
    .carre {
        display: inline-block;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        transform: translateY(4px);
        margin-right: var(--marge-xs);

        &.avec-bordure {
            border: 1px solid var(--gris-clair);
        }
    }
    .fond-sombre {
        .carre.avec-bordure {
            border-color: var(--gris-fonce);
        }
    }
}

/* VARIABLES DE THEME OUEST-FRANCE */

@import "palette";

// voir https://robots.thoughtbot.com/sass-default pour explications sur !default

// Import Fonts
$font-import-url:        'https://cdn.sipaof.fr/sipaui/latest/fonts/oswald/Oswald' !default;
$font-import-use-local:  null        !default;
                            // $font-import-use-local:  true;
$do-not-use-negative:    null        !default;
$font-import-directory:  '../fonts'  !default;
$font-import-path:       'oswald/'   !default;
$font-import-medium:     null        !default;
$font-import-bold:       null        !default;
$font-import-name:       'Oswald'    !default;
$icons-display-optional:  null       !default;

$font-family-base: Arial, Helvetica, sans-serif      !default;
$font-family-secondaire: 'Oswald', $font-family-base !default;


// CONTAINER: ; --------------------------------------------------------------------------------------------------------------
$max-width-container-md:    768px      !default;
$max-width-container-lg:    960px      !default;
$max-width-container-xl:    1280px     !default;

$marge-container-xs: var(--marge-m)    !default;
$marge-container-sm: var(--marge-l)    !default;
$marge-container-md: var(--marge-l)    !default;
$marge-container-lg: var(--marge-s)    !default;

// BREAKPOINTS --------------------------------------------------------------------------------------------------------------
$min-breakpoint-sm:   22.5em   !default;   /* 360px  */
$min-breakpoint-md:   48em     !default;   /* 768px  */
$min-breakpoint-lg:   61.25em  !default;   /* 980px  */
$min-breakpoint-xl:   80em     !default;   /* 1280px */

$max-breakpoint-xs:  22.438em !default;   /* 359px  */
$max-breakpoint-sm:  47.938em !default;   /* 767px  */
$max-breakpoint-md:  61.188em !default;   /* 979px  */
$max-breakpoint-lg:  79.938em !default;   /* 1279px */

// COULEURS SÉMANTIQUES ------------------------------------------------------------------------------------------------------
$color-main-1:                  $blanc               !default;
$color-main-2:                  $gris-plus-clair     !default;
$color-main-3:                  $gris-clair          !default;
$color-main-4:                  $gris                !default;
$color-main-5:                  $gris-fonce          !default;
$color-main-6:                  $gris-plus-fonce     !default;
$color-main-7:                  $anthracite          !default;
$color-main-8:                  $noir                !default;


$color-secondary-1:             $rouge-1             !default;
$color-secondary-2:             $rouge-2             !default;

$color-important:               $color-secondary-1   !default;

$color-subscription-1:          $jaune-1             !default;
$color-subscription-2:          $jaune-2             !default;

$color-sponsorised-1:           $color-main-3        !default;

$color-error:                   $rouge-1             !default;
$color-success:                 $vert-1              !default;

$color-code:                    adjust-color($color-secondary-1, $lightness: -3%)      !default;
$background-pre-code:           $color-main-2                                          !default;
$color-kbd:                     $color-main-1                                          !default;
$background-kbd:                $color-main-6                                          !default;

$color-metadata:                $color-main-5                                          !default;

$opacity-hover:                 var(--opacite-survol)                                  !default;


$color-picto:                   $color-main-5                      !default;
$color-picto-hover:             rgba($color-picto, $opacity-hover) !default;
$color-picto-active:            $color-main-7                      !default;


// GLOBAL --------------------------------------------------------------------------------
$font-size-root:                62.5%;
$background-body:               $color-main-1;


// TYPO ----------------------------------------------------------------------------------

$font-size-standard:            var(--font-size-ms)            !default;
$font-size-standard-edito:      var(--font-size-m)             !default;
$font-size-metadata:            var(--font-size-xs)            !default;
$font-size-metadata-edito:      var(--font-size-s)             !default;

$font-size-icons-standard:      2.4rem                         !default;
$font-size-icons-small:         1.4rem                         !default;

$font-color-standard:           $color-main-7                  !default;
    $font-color-light:              $color-main-6              !default;
        $font-color-xlight:             $color-main-5          !default;

$font-weight-standard: var(--font-weight-normal)    !default;
$font-weight-bold:     var(--font-weight-bold)      !default;
$line-height-standard: var(--line-height-m)         !default;
$line-height-edito:    var(--line-height-l)         !default;


// LIENS --------------------------------------------------------------------------------
$color-link-standard:                $font-color-standard                           !default;
// $color-link-standard-hover:          rgba($font-color-standard, $opacity-hover)     !default;
$color-link-standard-hover:          inherit                                        !default;
$opacity-link-standard-hover:        $opacity-hover                                 !default;


// COINS ARRONDIS --------------------------------------------------------------------------------
$border-radius-standard:             var(--border-radius-standard)                  !default;
$border-radius-small:                var(--border-radius-small)                     !default;

// Sélecteurs
$inputTextBase: 'input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="number"], input[type="search"], input[type="url"], textarea, .su-input-text, .su-textarea';
$inputTextPlus: $inputTextBase + ', input[type="date"]', '.su-fake-field';










// COULEURS SUR FOND SOMBRE ------------------------------------------------------------------------------------------------------
$color-main-1-negative:              $anthracite                    !default;
$color-main-2-negative:              $anthracite                    !default;
$color-main-3-negative:              $anthracite                    !default;
$color-main-4-negative:              $gris-fonce                    !default;
$color-main-5-negative:              $gris                          !default;
$color-main-6-negative:              $gris-plus-clair               !default;
$color-main-7-negative:              $blanc                         !default;
$color-main-8-negative:              $blanc                         !default;

$color-secondary-1-negative:         $color-secondary-2             !default;
$color-secondary-2-negative:         $color-secondary-2             !default;

$color-important-negative:           $color-secondary-1-negative    !default;

$color-subscription-1-negative:      $jaune-2                       !default;
$color-subscription-2-negative:      $jaune-2                       !default;

$color-sponsorised-1-negative:       $color-main-3-negative         !default;

$color-error-negative:               $rouge-2                       !default;
$color-success-negative:             $vert-2                        !default;

$color-code-negative:                adjust-color($color-secondary-1-negative, $lightness: -3%)      !default;
$background-pre-code-negative:       $color-main-2-negative                                          !default;
$color-kbd-negative:                 $color-main-1-negative                                          !default;
$background-kbd-negative:            $color-main-6-negative                                          !default;

$color-metadata-negative:            $color-main-5-negative         !default;

$color-picto-negative:               $color-main-5-negative         !default;
$color-picto-hover-negative:         rgba($color-picto-negative, $opacity-hover) !default;
$color-picto-active-negative:        $color-main-7-negative         !default;


// TYPO
$font-color-negative:           $color-main-7-negative              !default;
    $font-color-light-negative:     $color-main-6-negative          !default;
        $font-color-xlight-negative:    $color-main-5-negative      !default;

// LIENS
$color-link-negative:                $font-color-negative                           !default;
// $color-link-negative-hover:          rgba($font-color-negative, $opacity-hover)     !default;
$color-link-negative-hover:          inherit                                        !default;

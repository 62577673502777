/* --------------------------------
    SITE SIPAUI
    CSS d’import
 -------------------------------- */

@import "../../../framework/core/scss/_variables-scss";
@import "../../../framework/composants/scss/Inputs/Buttons/_variables";



// Vars

$height-header: 64px;
$height-contenu-header: calc($height-header - 30px);

$largeur-nav-max: 360px !default;
$largeur-nav-min: 260px !default;
 
$largeur-bordure-zone-demo: 1px;
$padding-zone-demo: var(--marge-s);

$largeur-min-demo-tablette: calc(768px + ($padding-zone-demo *2) + ($largeur-bordure-zone-demo * 2));
$largeur-max-demo-tablette: calc(979px + ($padding-zone-demo *2) + ($largeur-bordure-zone-demo * 2));
$largeur-min-demo-desktop: calc(980px + ($padding-zone-demo *2) + ($largeur-bordure-zone-demo * 2));

$largeur-max-conteneur-desktop: 830px;
$largeur-max-conteneur-desktop-large: calc($largeur-min-demo-tablette + 40px);
$largeur-max-conteneur-desktop-extra-large: calc($largeur-max-demo-tablette - 20px);

// Mixins
@mixin boutons-demo {
    padding: var(--marge-s) var(--marge-m);
    border: 1px solid var(--gris);
    background: var(--gris-plus-clair);
}

// Éléments de style génériques ----------------------------
@import "0-redefinition-sipaui";
@import "0-generique";

// Header & outils---------------
@import "1-header";
@import "1-outils";
@import "1-navigation-secondaire";


// Pages ---------------
@import "2-accueil";

@import "2b-versions";

@import "2c-framework";

@import "2d-couleurs";
@import "2d-typographie";
@import "2d-logotheque";
@import "2d-variables";


/* --------------------------------
    SITE SIPAUI
    CSS génériques
-------------------------------- */

// !!! A SUPPRIMER QUAND SITE FINI !!!
.a-faire main article,
article:has(.a-faire-pug) {
    background: #fee;
}
main.contenu  nav.menu-secondaire {
    a[href*="020-a-propos/010"],
    a[href*="020-a-propos/020"] {
        color: red;
        background: rgba(255,230,230, .5) !important;
    }
}

// SCROLL------------------------------------------------------
[id] {
    scroll-margin-top: calc($height-header + 110px);
}

div:has(.su-hidden #selecteur-theme) [id],
.doc-js article [id] {
    scroll-margin-top: calc($height-header + 40px);
}

// ARCHI PAGES------------------------------------------------------
main.contenu {
    asside {
        z-index: 1;
    }
    article {
        z-index: 2;
    }
}
body {
    display: flex;
    flex-direction: column;
}

main.contenu {
    flex: 1;
    
}
#outils.su-hidden + .su-container > .su-article,
.doc-js #outils + .su-container > .su-article {
    padding-top: 130px;
    padding-bottom: var(--marge-xl);
}



// DÉGRADÉ BODY ------------------------------------------------------
body {    
    &:before {
        background: linear-gradient(180deg,var(--noir),transparent);
        content: "";
        display: block;
        height: 100px;
        opacity: .05;
        position: absolute;
        width: 100%;
        top: $height-header;
        z-index: -1;
    }
}

// OBJETS GÉNÉRIQUES ------------------------------------------------------

// Icônes sur ligne
.sur-ligne {
    display: inline-block;
    transform: translateY(3px);
}

// Trad. française des noms de composants
h1 {
    span.francais {
        font-size: var(--font-size-xxl);
        color: var(--gris-fonce);
    }
}

// Cartouche Versions
.version {
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  background: var(--gris-fonce);
  padding: 3px 13px;
  border-radius: 100px;
  display: inline-block;
}
.breaking .version,
.version.breaking {
  background: var(--rouge);
}

// Boîtes de code
.su-code-box {
    .hljs { // Surcharge styles HLJS
        background: transparent;
    }
    .su-button.copier {
        width: 25px;
        height: 25px;
        min-width: auto;
        padding: 0;
        position: absolute;
        top: var(--marge-xs);
        right: var(--marge-xs);
    
        &:after {
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MiIgdmlld0JveD0iMCAwIDI1IDI1Ij48cGF0aCBkPSJNMTYgOGgtMVY3SDh2OWgxdjFIN1Y2aDl2MloiLz48cGF0aCBkPSJNMTAgOWgxdjFoLTF6TTEyIDloMXYxaC0xek0xNCA5aDF2MWgtMXpNMTggOWgxdjFoLTF6TTE2IDloMXYxaC0xek0xMSAxMXYxaC0xdi0xek0xMSAxM3YxaC0xdi0xek0xMSAxN3YxaC0xdi0xek0xMSAxNXYxaC0xdi0xek0xOSAxMXYxaC0xdi0xek0xOSAxM3YxaC0xdi0xek0xOSAxN3YxaC0xdi0xek0xOSAxNXYxaC0xdi0xek0xMCAxOWgxdjFoLTF6TTEyIDE5aDF2MWgtMXpNMTQgMTloMXYxaC0xek0xOCAxOWgxdjFoLTF6TTE2IDE5aDF2MWgtMXoiLz48L3N2Zz4=) center no-repeat;
        }
    }
    &:has(.copier) {
        padding-right: calc(var(--taille-tactile-mini) + var(--marge-xs) + var(--marge-xs));
    }
}
    
// Boîte exemple
$border-radius-exemple : 5px;

P + .boite-exemple {
    margin-top: var(--marge-m);
}
.boite-exemple:has(+ h2),
.boite-exemple:has(+ h3),
.boite-exemple:has(+ h4) {
    margin-bottom: var(--marge-xl);
}
.boite-exemple:has(+ h5),
.boite-exemple:has(+ p) {
    margin-bottom: var(--marge-m);
}

.zone-demo {
    border: $largeur-bordure-zone-demo solid var(--gris);
    border-radius: $border-radius-exemple;
    box-shadow: inset 0 0 10px rgba(0,0,0, .1);
    padding: var(--marge-s) $padding-zone-demo;
    background: $background-body;
    
    &.dark {
        background-color: #121212;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    }
    &.desktop {
        width: calc(100vw - $largeur-nav-max);
        min-width: $largeur-min-demo-desktop;
        max-width: unset;
        margin-left: calc((100vw - $largeur-nav-min - 810px) * -.5);
    }
    &.mobile {
        width: calc(360px + ($largeur-bordure-zone-demo + $padding-zone-demo) * 2);
        min-width: unset;
    }
    &.libre {
        resize: both;
        overflow: auto;
        min-width: unset;
        max-width: unset;
        
        iframe {
            height: 100% !important;
            min-height: 200px;
        }
    }
}
.demo {
    display: block;
    width: 100%;
    height: 80px;
    border: 0;
}

// Boîtes de code + Démo
.su-code-box:has(+ .zone-demo) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: -1px;
}
.su-code-box + .zone-demo {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: 0;
    
    &.desktop {
        border-radius: $border-radius-exemple;
    }
}
// Boutons de la démo
.zone-demo + .zone-boutons {
    display: flex;
    transform: translateY(-1px);
    
    button {
        @include boutons-demo;
        
        &:not(.actif):hover,
        &:not(.actif):focus,
        &:not(.actif):active {
            opacity: var(--opacite-survol);
        }
        &.actif {
            background: var(--gris-clair);
            cursor: default;
        }
    }
    li + li {
        margin-right: -1px;
    }
    li:first-child button {
        border-bottom-left-radius: $border-radius-exemple;
    }
    li:last-child button {
        border-bottom-right-radius: $border-radius-exemple;
    }
}

// Informations, alertes et liste de classes dispo
.info,
.alerte,
.liste-classes {
    padding: var(--marge-s) var(--marge-m);
    border-left: 2px solid transparent;
}
.info,
.alerte {
    padding-left: 45px;
    position: relative;
    
    &::after {
        font-family: su-icons;
        font-size: 24px;
        position: absolute;
        top: var(--marge-xs);
        left: var(--marge-s);
    }
}

.su-article {
    .info,
    .alerte,
    .liste-classes {
        margin: var(--marge-l) 0 var(--marge-xl);
        
        > :first-child {
            margin-top: 0;
        }
    }
    .liste-classes {
        background: #f5f5f5;
        border-color: var(--gris-clair);
        margin-top: var(--marge-xxxl);
        
        h3 {
            margin-top: var(--marge-xl);
        }
    }
}
.info {
    background: #fef6e4;
    border-color: var(--jaune-2);
    
    &::after {
        content: "\e92b";
        color: var(--jaune);
    }
}
.alerte {
    background: #fff3f2;
    border-color: var(--rouge);
    
    &::after {
        content: "\e904";
        color: var(--rouge);
    }
}

// Cartes
[class*="carte-"] {
    h3 {
        margin-top: 0 !important;
    }
    .vignette {
        background: linear-gradient(135deg,#eee,#eee 10%,#dadada 0,#dadada 20%,#eee 0,#eee 30%,#dadada 0,#dadada 40%,#eee 0,#eee 50%,#dadada 0,#dadada 60%,#eee 0,#eee 70%,#dadada 0,#dadada 80%,#eee 0,#eee 90%,#dadada 0,#dadada);
        padding: 10px;
        height: 100px;
        
        img {
            height: 100%;
            margin: auto;
            display: block;
        }
    }
}

// OK - KO
.zone-ok-ko,
.zone-ok-ko-1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    figure {
        text-align: center;
    }
    figcaption {
        font-size: var(--font-size-s);
    }
}
.zone-ok-ko-1 {
    justify-content: center;
}
.ok,
.ko {
    overflow: hidden;
    margin: var(--marge-m) 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    .valeur {
        color: var(--blanc);
        font-weight: var(--font-weight-bold);
        padding-left: var(--marge-s);
        margin: 0 calc(var(--marge-m) * -1) calc(var(--marge-m) * -1);
    }
}
.ok {
    .valeur {
        background: var(--vert);
    }
}
.ko {
    .valeur {
        background: var(--rouge);
    }
}

// Catalogues
[class*="catalogue"] {
    .case {
        flex: 0;
        border: 1px solid #d4d4d4;
        margin: -1px 0 0 -1px;
        text-align: center;
        padding: var(--marge-s) var(--marge-m);
        min-width: 100%;
        
        label,
        pre {
            text-align: left;
        }
    }
}

// Liste boutons
.liste-boutons {
    display: flex;
    gap: var(--marge-m);
    justify-content: center;
}

// Décalage pour rester aligner à gauche avec le texte sous une liste (ie p. icônes) 
.decalage-liste {
    padding-left: var(--marge-m);
}


// ------------------------------------------------------------------ TABLETTE ------------------------------------------------------------------
@media screen and (min-width: 48em) { // >768px
    // Catalogues
    [class*="catalogue"] .case {
        min-width: 50%;
    }
}

// ------------------------------------------------------------------ DESKTOP ------------------------------------------------------------------
@media screen and (min-width: 61.25em) { // >980px
    // Boutons de la démo
    .zone-demo {
        min-width: $largeur-min-demo-tablette;
        max-width: $largeur-max-demo-tablette;
    
        &:has(+ .zone-boutons) {
            border-bottom-left-radius: 0;
        }
    }
}

// ------------------------------------------------------------------ DESKTOP ERREUR------------------------------------------------------------------
@media screen and (min-width: 61.25em) and (max-width: 83.875em) { // >980px <1342px
    .zone-demo.desktop {
        padding-top: 40px;
        position: relative;
    }
    .zone-boutons {
        li:nth-child(3) {
            display: none;
        }
    }
}


// ------------------------------------------------------------------ DESKTOP LARGE------------------------------------------------------------------
@media screen and (min-width: 80em) { // >1280px
    // Zone de démo
    .zone-demo.desktop {
        margin-left: calc((100vw - $largeur-nav-max - 810px) * -.5);
    }
    // Catalogues
    [class*="catalogue"] .case {
        min-width: 33.4%;
    }
}

// ------------------------------------------------------------------ DESKTOP EXTRA LARGE---------------------------------------------------------------
@media screen and (min-width: 100em) { // >1600px
    // Zone de démo
    .zone-demo.desktop {
        margin-left: calc((100vw - $largeur-nav-max - 960px) * -.5);
    }
}
